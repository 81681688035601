<template>
  <validation-observer ref="simpleRules">
    <b-row>
      <b-col>
        <div id="component-breadcrumbs">
          <b-breadcrumb
            class="breadcrumb-slash"
            :items="breadcrumbItems"
          />
        </div>
      </b-col>
      <b-col v-if="readOnly">
        <div class="float-right mr-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click.prevent="editScreen()"
          >
            Edit
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-form>
      <b-row>
        <b-col
          class="pr-5 white-bg-container"
        >
          <b-row class="pb-2 pt-2">
            <b-col md="5">
              <img src="../../assets/images/logo/ProfileSilouette.png">
            </b-col>
            <b-col md="4" />
            <b-col>
              <b-row>
                <b-col>
                  <h3>{{ CurrentClient.Name }}</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h3>{{ CurrentClient.CurrentBranch.Name }}</h3>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr>
          <b-row class="pb-2 pt-2">
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-group
                  label="First Name"
                  label-for="firstname"
                  class="required-data"
                >
                  <b-form-input
                    id="firstname"
                    v-model="requestorObject.FirstName"
                    placeholder=""
                    name="firstname"
                    :state="errors.length > 0 ? false:null"
                    :readonly="readOnly"
                    @keypress.enter.prevent
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Middle Name"
                label-for="middlename"
              >
                <b-form-input
                  id="middlename"
                  v-model="requestorObject.MiddleName"
                  placeholder=""
                  name="middlename"
                  :readonly="readOnly"
                  @keypress.enter.prevent
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-group
                  label="Last Name"
                  label-for="lastname"
                  class="required-data"
                >
                  <b-form-input
                    id="lastname"
                    v-model="requestorObject.LastName"
                    placeholder=""
                    name="lastname"
                    :state="errors.length > 0 ? false:null"
                    :readonly="readOnly"
                    @keypress.enter.prevent
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="User Role"
                rules="required"
              >
                <b-form-group
                  label="User Role"
                  label-for="Roles"
                  class="required-data"
                >
                  <v-select
                    id="Roles"
                    v-model="SelectedRoles"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="userRoles"
                    multiple
                    placeholder="User Roles"
                    name="Roles"
                    :disabled="readOnly"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="pb-2 pt-2">
            <b-col md="3">
              <b-form-group
                label="Title"
                label-for="title"
              >
                <b-form-input
                  id="titel"
                  v-model="requestorObject.Title"
                  name="title"
                  placeholder=""
                  :readonly="readOnly"
                  @keypress.enter.prevent
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Department"
                label-for="department"
              >
                <b-form-input
                  id="department"
                  v-model="requestorObject.Department"
                  placeholder=""
                  name="department"
                  :readonly="readOnly"
                  @keypress.enter.prevent
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <b-form-group
                  label="Status"
                  label-for="status"
                  class="required-data"
                >
                  <v-select
                    id="status"
                    v-model="Status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="option"
                    placeholder=""
                    name="status"
                    :disabled="readOnly"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Authentication Provider"
                label-for="authProvider"
              >
                <div style="padding-bottom: 20px;">
                  <v-select
                    id="authProviderId"
                    v-model="SelectedAuthProvider"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="authProviders"
                    placeholder="Auth Provider"
                    @input="onAuthProviderChanged"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pb-2 pt-2">
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                  class="required-data"
                >
                  <b-form-input
                    id="email"
                    v-model="requestorObject.EMail"
                    placeholder=""
                    name="email"
                    :state="errors.length > 0 ? false:null"
                    :readonly="!!SelectedAuthProvider && !!requestorObject.UserAccountID"
                    @keypress.enter.prevent
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="pb-2 pt-2">
            <b-col>
              <b-form-group
                label="Phone"
                label-for="Phone"
              >
                <b-form-input
                  id="Phone"
                  v-model="requestorObject.Phone"
                  v-mask="'(###) ###-####'"
                  type="tel"
                  placeholder=""
                  name="phone"
                  :readonly="readOnly"
                  @keypress.enter.prevent
                />
              </b-form-group>

            </b-col>
            <b-col>
              <b-form-group
                label="Ext"
                label-for="PhoneExt"
              >
                <b-form-input
                  id="PhoneExt"
                  v-model="requestorObject.PhoneExt"
                  type="number"
                  placeholder=""
                  name="PhoneExt"
                  :readonly="readOnly"
                  @keypress.enter.prevent
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Mobile/SMS"
                label-for="MobileSms"
              >
                <b-form-input
                  id="MobileSms"
                  v-model="requestorObject.MobileSms"
                  placeholder=""
                  name="MobileSms"
                  :readonly="readOnly"
                  @keypress.enter.prevent
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pb-5 pt-2">
            <b-col>
              <b-form-group
                label="Special Instructions Investigator"
                label-for="instructionInvestigator"
              >
                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedInstructionsInvestigator}"
                >
                  <textarea
                    id="instructionInvestigator"
                    v-model="requestorObject.SpecialInstructionsInvestigator"
                    class="expandable-textarea"
                    :class="[isExpandedInstructionsInvestigator ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="readOnly"
                  />
                  <div @click="isExpandedInstructionsInvestigator = !isExpandedInstructionsInvestigator">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedInstructionsInvestigator ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pb-5 pt-2">
            <b-col>
              <b-form-group
                label="Special Instructions Manager"
                label-for="instructionManager"
              >
                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedInstructionsManager}"
                >
                  <textarea
                    id="instructionManager"
                    v-model="requestorObject.SpecialInstructionsManager"
                    class="expandable-textarea"
                    :class="[isExpandedInstructionsManager ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="readOnly"
                  />
                  <div @click="isExpandedInstructionsManager = !isExpandedInstructionsManager">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedInstructionsManager ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Requestor Billing Notes"
                label-for="requestorBillingNotes"
              >

                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedBilling}"
                >
                  <textarea
                    id="requestorBillingNotes"
                    v-model="requestorObject.BillingNotes"
                    class="expandable-textarea"
                    :class="[isExpandedBilling ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="readOnly"
                  />
                  <div @click="isExpandedBilling = !isExpandedBilling">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedBilling ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-if="!readOnly"
            class="pb-2 pt-2"
          >
            <b-col>
              <div class="d-flex justify-content-between">
                <div>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                    @click="resetForm()"
                  >
                    Cancel
                  </b-button>
                </div>
                <div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    :disabled="isLoading"
                    @click.prevent="validationForm()"
                  >
                    Save
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BBreadcrumb} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vSelect from 'vue-select'
import { required, email } from '@validations'
import APIService from "@core/utils/APIService";
import {mapGetters} from "vuex";

const apiService = new APIService();

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    BBreadcrumb,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      requestorObject: {},
      SelectedRoles: [],
      userRoles: [],
      allowedRoles: ["Client User", "Branch Supervisor", "Client Supervisor"],
      authProviders: [],
      SelectedAuthProvider: null,
      option: [{ title: 'Active', value: 'Active' }, { title: 'Locked', value: 'Locked' }, { title: 'Inactive', value: 'Inactive' }],
      phoneTypeOption: [{ title: 'Office', value: 'Office' }, { title: 'Cell', value: 'Cell' }],
      dealOwner: [],
      Status: { title: 'Active', value: 'Active' },
      CurrentClient: {
        CurrentBranch: {},
      },
      isExpandedInstructionsManager: false,
      isExpandedInstructionsInvestigator: false,
      isExpandedBilling: false,
      readOnly: false,
      isLoading: false,
      required,
      email,
    }
  },
  computed: {
    breadcrumbItems: function() {
      return [
        {
          text: 'Clients',
          href: '/clients'
        },
        {
          text: this.CurrentClient.Name ? this.CurrentClient.Name : 'Client',
          href: '/clients/view/' + this.CurrentClient.ClientID
        },
        {
          text: this.CurrentClient.CurrentBranch.Name ? this.CurrentClient.CurrentBranch.Name : 'Branch',
          href: '/clients/' + this.CurrentClient.ClientID + '/branch/' + this.CurrentClient.CurrentBranch.BranchID + '/edit'
        },
        {
          text: this.requestorObject.UserAccountID ? this.requestorObject.LastName + ", " + this.requestorObject.FirstName : 'Add New requestor'
        }
      ]
    }
  },
  async created() {
    try {
      const response = await apiService.get('auth/providers').then(res => res.data);
      const providers = response.map(data => ({ title: data.Name, value: data.ID }));
      var noProviderValue = { title: '(no auth provider)', value: '' };
      providers.unshift(noProviderValue);
      this.authProviders = providers;
    } catch (err) {
      this.error = err
    }
    try {
      const responseRoles = await apiService.get('permissions/roles').then(res => res.data)
      let roles = responseRoles.map(data => ({ title: data.Name, value: data.ID }));
      roles = roles.filter(i => this.allowedRoles.indexOf(i.title) !== -1)
      const obj = Object.fromEntries(
          roles.map(count => [count, {
            title: count,
          }]),
      )
      this.userRoles = roles
      this.SelectedRoles = roles.filter(i => i.title === "Client User")
      const response = await apiService.get('users/user').then(res => res.data).catch(error => error)
      this.dealOwner = response.Users.map(d => ({title: d.LastName + ", " + d.FirstName, value: d.UserAccountID}))
      if (this.dealOwner && this.userRoles) {
        const existingRequestorID = this.$route.params.requestorID;
        const existingClientID = this.$route.params.clientID;
        const existingBranchID = this.$route.params.branchID;
        this.readOnly = this.$route.meta.view || false;
        if (existingRequestorID) {
          apiService
              .get("client/requestor/" + existingRequestorID + "/detail")
              .then((response) => {
                this.requestorObject = response.data;
                this.setCurrentAuthProvider();
                this.Status = this.option.filter(i => i.value === response.data.CRMStatus)
                this.SelectedRoles = response.data.Roles.map(i => this.userRoles.filter(x => x.value === i.RoleID))[0];
              });
        }
        if (existingClientID) {
          apiService
              .get("client/" + existingClientID + "/detail")
              .then((response) => {
                this.CurrentClient = {...this.CurrentClient, ...response.data};
              })
        }
        if (existingBranchID) {
          apiService
              .get("client/branch/" + existingBranchID + "/detail")
              .then((response) => {
                this.CurrentClient.CurrentBranch = {...this.CurrentClient.CurrentBranch, ...response.data};
              })
        }
      }
    } catch (err) {
      this.error = err
    }
  },
  mounted() {},
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    setCurrentAuthProvider() {
      if (this.requestorObject === null || this.requestorObject === undefined) { return; }
      if (this.authProviders === null || this.authProviders.length === 0) { return; }
      if (this.requestorObject.AuthProviderID === null) { return; }
      var currentProvider = this.authProviders.filter(obj => {
        return obj.value === this.requestorObject.AuthProviderID
      });
      this.SelectedAuthProvider = currentProvider;
    },
    onAuthProviderChanged(value) {
      this.requestorObject.AuthProviderID = value ? value.value : null;
    },
    resetForm() {
      this.requestorObject = {};
      this.SelectedRoles = [];
      this.$router.push('/clients/' + this.CurrentClient.ClientID + '/branch/' + this.CurrentClient.CurrentBranch.BranchID + '/edit');
    },
    processForm() {
      this.isLoading = true;
      var postData = {};
      postData.Department = this.requestorObject.Department;
      postData.EMail = this.requestorObject.EMail;
      postData.FirstName = this.requestorObject.FirstName;
      postData.MiddleName = this.requestorObject.MiddleName;
      postData.LastName = this.requestorObject.LastName;
      postData.MobileSms = this.requestorObject.MobileSms;
      postData.Phone = this.requestorObject.Phone;
      postData.PhoneExt = this.requestorObject.PhoneExt;
      postData.Title = this.requestorObject.Title;
      postData.BillingNotes = this.requestorObject.BillingNotes;
      postData.SpecialInstructionsManager = this.requestorObject.SpecialInstructionsManager;
      postData.SpecialInstructionsInvestigator = this.requestorObject.SpecialInstructionsInvestigator;
      postData.Status =(this.Status && this.Status.value) || this.Status[0].value;
      postData.Roles = this.SelectedRoles.map(x => x.value);
      postData.AuthProviderID = this.requestorObject.AuthProviderID;
      postData.UserType = "Client";


      var endpoint = 'client/branch/' + this.CurrentClient.CurrentBranch.BranchID + '/requestor/add';
      if (this.requestorObject.UserAccountID) {
        endpoint = 'client/requestor/' + this.requestorObject.UserAccountID + '/edit'
      }

      apiService.post(endpoint, postData).then(res => {
        if (res) {
          this.showToast('success', 'top-center', 4000, 'Requestor saved!');
          this.$router.push('/clients/' + this.CurrentClient.ClientID + '/branch/' + this.CurrentClient.CurrentBranch.BranchID + '/edit');
        } else {
          this.isLoading = false;
        }
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.processForm()
        }
      })
    },
    editScreen() {
      this.readOnly = false;
      this.isLoading = false;
    }
  },
}
</script>

<style>
hr{
  border: 1px solid #ebe9f1;
}
</style>
